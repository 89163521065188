@use '../base/variables';

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .shadow {
    display: none !important;
  }
}

.tabs__item {
  display: flex;
  flex: 0 0 auto;
  max-width: 200px;
  z-index: 30;
}

.tabs__link {
  display: flex;
  margin-bottom: 0;
  padding: 12px 12px 20px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(variables.$text-color, 0.5);
  text-align: center;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  background-color: inherit;
  transition-property: border-bottom-color, color;
  transition-duration: .5s;
  //transition-timing-function: cubic-bezier(.4, 0, .2, 1);

  &[active] {
    border-bottom-color: variables.$vermilion;
    color: variables.$text-color;
  }

  &:hover:not(&[active]) {
    color: rgba(variables.$text-color, 0.8);
    border-bottom-color: rgba(variables.$vermilion, 0.5);
  }

  h2 {
    align-self: center;
  }
}

.tabs-content {
  padding: 32px 24px;
  background-color: variables.$white;
}

.tabs-content__flex-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
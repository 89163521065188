@use 'base/base';

@use 'components/accordion';
@use 'components/section';
@use 'components/swiper';
@use 'components/menu';
@use 'components/tabs';
@use 'components/panel';

@use 'blocks/main';
@use 'blocks/experience';
@use 'blocks/training';
@use 'blocks/achievements';
@use 'blocks/children';
@use 'blocks/materials';
@use 'blocks/fine-art';
@use 'blocks/links';
@use 'blocks/feedback';
@use 'blocks/leisure';
@use 'blocks/gallery';
@use 'blocks/theater';
@use 'blocks/color-therapy';

@import "@material-tailwind/html/styles/material-tailwind.css";

@use "variables";

// Global styles for text
body {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.link {
  width: fit-content;
  border-bottom: 1px variables.$vermilion dotted;

  &:hover {
    color: variables.$vermilion;
  }
}

.source {
  margin-top: 12px;
  color: rgba(variables.$text-color, 0.5);
  font-size: 14px;

  &_right {
    text-align: right;
  }
}

.button {
  display: inline-block;
  margin-top: 24px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;

  transition-property: box-shadow;
  transition-duration: .5s;

  &_red {
    background-color: variables.$white;
    border: 2px solid rgba(variables.$vermilion, 0.5);

    &:hover {
      box-shadow: 0 0 5px 0 rgba(variables.$vermilion, 0.5);
    }
  }

  &_white {
    border: 2px solid rgba(variables.$white, 0.5);

    &:hover {
      box-shadow: 0 0 10px 0 rgba(variables.$white, 0.5);
    }
  }
}
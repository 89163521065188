@use '../base/variables';

.panel {
  display: flex;

  .shadow {
    display: none !important;
  }
}

.themes {
  flex: 0 0 280px;
  margin-right: 24px;
}

.themes__item {
  z-index: 30;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(variables.$text-color, 0.5);
  }
}

.themes__link {
  display: block;
  padding: 8px 12px 4px;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
  background-color: inherit;
  color: rgba(variables.$text-color, 0.5);

  transition-property: color;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);

  &[active],
  &:hover {
    color: variables.$text-color;
  }
}

.panel-content {
  flex: 1 1 900px;
  width: 900px;
}

@use 'src/scss/base/variables';

.feedback-section {
  background: linear-gradient(188deg, variables.$blue 0%, variables.$white 50%, variables.$night-blue 100%);
}

.feedback-item {
  display: flex;
  margin-bottom: 32px;
  border-radius: 8px;
  background-color: variables.$white;

  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(variables.$text-color, 0.5) 0 4px 6px -1px,
  rgba(variables.$text-color, 0.5) 0 2px 4px -2px;
}

.feedback-item__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 330px;
  flex: 0 0 330px;
  padding: 20px 32px 20px;
  border-right: 2px solid variables.$blue;
}

.feedback-item__photo {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-bottom: 32px;
}

.feedback-item__name {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
}

.feedback-item__body {
  position: relative;
  margin: 24px 20px 24px 20px;
  background-color: variables.$white;
  padding: 20px 32px 32px;
  border-radius: 8px 8px 8px 0;

  &::before {
    position: absolute;
    content: '';
    border: 15px solid transparent;
    border-right: 10px solid variables.$white;
    border-bottom: 10px solid variables.$white;
    bottom: 0;
    left: -20px;
  }
}

.feedback-item__text {
  color: variables.$text-color;
  font-size: 16px;
  line-height: 1.5;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
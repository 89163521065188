@use '../base/variables';

.menu {
  position: relative;
  z-index: 2;
  background-color: variables.$night-blue;
  box-shadow: 0 1px 5px variables.$white;
}

.menu__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  width: 1280px;
  margin: 0 auto;
  padding: 20px 0;
  color: rgba(variables.$white, 0.7);
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.menu__item {
  max-width: 230px;

  &:nth-of-type(3) {
    width: 150px;
  }

  &_active {
    color: variables.$white;
  }
}

.menu__link {
  display: block;
  text-align: center;

  &:hover {
    color: variables.$white;
  }
}
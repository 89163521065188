@use 'src/scss/base/variables';

.accordion-item {
  position: relative;
  border-radius: 8px;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 16px 32px 16px 32px;
  text-align: left;
  font-weight: 600;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);

  &[open] {
    .accordion-icon {
      transform: rotateX(180deg);
    }
  }
}

.accordion-icon {
  position: absolute;
  right: 16px;
  //padding-top: 4px;
  font-size: 16px;
  line-height: 20px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.accordion-collapse {
  height: 0;
  overflow: hidden;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.accordion-body {

}
@use 'src/scss/base/variables';

.fine-art-section {
  background: linear-gradient(188deg, variables.$light 0%, variables.$blue 100%);
}

.fine-art-section__subtitle {
  font-size: 24px;
  line-height: 32px;
  //text-align: center;
  margin: 0 auto 32px;

  &_bold {
    font-weight: bold;
  }
}

.lesson {
  background-color: rgba(variables.$white, 1);
}

.lesson__title {
  cursor: auto;
  font-size: 20px;
}

.lesson__body {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  padding: 16px 22px 12px 32px;
}

.lesson__content {
  padding-right: 40px;
  font-size: 16px;
}

.lesson__subtitle {
  margin-bottom: 16px;
}

.lesson__text {
  margin-bottom: 12px;
}

.lesson__list {
  padding-left: 32px;
  list-style: circle; // TODO
}

.lesson__item {
  margin-bottom: 8px;
}

.lesson__pictures {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 40% 0 0;
  margin-left: auto;

  &_flex_60 {
    flex-basis: 60%;
  }

  &_flex_50 {
    flex-basis: 50%;
  }

  &_flex_30 {
    flex-basis: 30%;
  }
}

.lesson__img {
  height: 200px;
  margin: 0 10px 20px;
}

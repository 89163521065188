@use 'src/scss/base/variables';

.links-section {
  //background-color: variables.$light;
}

.links-section__content {
  display: flex;
  justify-content: space-between;
}

.links-section__list {
  width: 48%;
}

.links-section__item {
  position: relative;
  padding-left: 32px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.5;
  color: variables.$text-color;

  &:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    position: absolute;
    left: 2px;
    top: 8px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: variables.$vermilion;
  }
}
@use 'src/scss/base/variables';

.color-therapy-section {
  background-color: variables.$light;
}

.color-therapy-section__wrapper {
  display: flex;
  justify-content: space-between;
}

.color-therapy-section__content {
  width: 48%;
  flex: 0 0 48%;
}

.color-therapy-section__gallery {
  width: 48%;
  flex: 0 0 48%;
  margin-bottom: 32px;
}

.color-therapy-section__card {
  padding: 32px;
  background-color: variables.$white;
  border-radius: 8px;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.color-therapy-section__card-title {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
}

.color-therapy-section__card-list {
  padding-left: 32px;
  list-style: circle;
}

.color-therapy-section__card-text {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
}
@use 'src/scss/base/variables';

.gallery-section {
  background: linear-gradient(146deg, rgba(0,129,167,1) 10%, rgba(254,217,183,1) 50%, rgba(240,113,103,1) 100%);
}

.gallery-section__block {
  margin-bottom: 48px;
  background-color: variables.$white;
  padding: 32px;
  border-radius: 8px;
}

.gallery-section__block-title {
  margin-bottom: 32px;
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
}

.gallery-section__videos {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.gallery-section__video {
  width: 48%;
}
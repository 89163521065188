@use 'src/scss/base/variables';

.experience-section {
  //background-color: variables.$light;
}

.experience-section__container {
  //padding-top: 60px;
  display: flex;
}

.experience-section__left-part {
  width: 50%;
  padding-right: 32px;
  border-right: 1px solid variables.$vermilion;
}

.experience-section__right-part {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 32px;
}

.experience-section__photo {
  margin-bottom: 52px;
}

.experience-section__subtitle {
  font-size: 32px;
  line-height: 1.5;
  font-weight: bold;
  color: variables.$vermilion;
  margin-bottom: 24px;
}

.experience-section__list {
  &:not(:last-of-type) {
    //border-bottom: 1px solid variables.$vermilion;
    margin-bottom: 32px;
  }
}

.experience-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 32px;
  font-size: 20px;
  line-height: 1.5;
  color: variables.$text-color;

  &:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    position: absolute;
    left: 2px;
    top: 8px;
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: variables.$vermilion;
  }
}

.experience-item__time {
  font-weight: bold;
}
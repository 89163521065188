@use 'src/scss/base/variables';

.materials-section {
  background-color: variables.$light;
}

.materials-section__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.materials-section__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 12px 24px;
  padding: 24px;
  text-align: center;
  background-color: variables.$white;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(variables.$vermilion, 0.5) 0 4px 6px -1px,
  rgba(variables.$vermilion, 0.5) 0 2px 4px -2px;

  &_full-w {
    width: 100%;
    flex: 0 0 100%;
  }
}

.materials-section__item-title {
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
}

.materials-section__item-text {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.materials-section__item-link {
  margin: auto auto 0;
}

.materials-section__item-video {
  margin-bottom: 24px;
  width: 660px;
  height: 400px;
}
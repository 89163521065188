@use 'src/scss/base/variables';

.section {
  //min-height: 50vh;
  //margin-bottom: 60px;
}
.section__title {
  margin-bottom: 32px;
  font-size: 40px;
  font-weight: bold;
  line-height: 60px;
  color: variables.$text-color;
  //text-align: center;

  &_light {
    color: variables.$white;
  }

  &_center {
    text-align: center;
  }
}

.section__container {
  max-width: 1280px;
  width: 1280px;
  margin: 0 auto;
  padding: 90px 0 120px;

  &_flex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}
@use 'src/scss/base/variables';

.swiper {
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-color: #0081A7;
}

.swiper-slide {
  width: auto !important;
  margin-bottom: 30px;
}

.swiper-pagination {
  //background-color: rgba(variables.$white, 0.5);
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  pointer-events: initial !important;
}

.creatures-swiper,
.nature-swiper,
.book-graphics-swiper,
.art-swiper,
.experiments-swiper,
.stories-swiper,
.mnemonic-table-swiper,
.emotions-swiper,
.modeling-swiper,
.origami-swiper {
  --swiper-pagination-color: #F07167;
  --swiper-navigation-color: #F07167;
}

.training-swiper,
.achievements-swiper,
.children-swiper {
  --swiper-pagination-color: #0081A7;
  --swiper-navigation-color: #0081A7;
}
@use '../base/variables';

.leisure-section {
  background-color: variables.$light;
}

.leisure-section__tabs {
  margin-bottom: 8px;
  padding-bottom: 4px;
  background-color: variables.$white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 1px 8px variables.$light;
}

.leisure-section__block {
  margin-bottom: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid variables.$vermilion;

  &_half {
    width: 48%;
  }

  &_2-3 {
    width: 60%;
  }

  &_1-3 {
    width: 35%;
  }

  &_last {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.leisure-section__block-title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.leisure-section__block-image {
  display: block;
  width: 100%;
}

.leisure-section__desc {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;

  &_important {
    color: variables.$vermilion;
    font-weight: bold;
  }
}

.leisure-section__book {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.leisure-section__book-title {
  font-weight: bold;
}

.leisure-section__book-public {
  font-style: italic;
}

.leisure-section__flex-wrapper {
  display: flex;
  justify-content: space-between;
}

.game-section__desc {
  margin-bottom: 16px;
  max-width: 780px;
}

.leisure-section__principles-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}

//---------- ACCORDIONS -----------------------------------
.game {
  background-color: rgba(variables.$light, 0.2);

  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(variables.$vermilion, 0.5) 0 4px 6px -1px,
  rgba(variables.$vermilion, 0.5) 0 2px 4px -2px;
}

.game__body {
  padding: 0 32px 12px;
}

.game__body-text {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
}

.game__body-quotation {
  margin-left: 32px;
}

.poem-accordion-item {
  background-color: rgba(variables.$light, 0.2);

  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(variables.$vermilion, 0.5) 0 4px 6px -1px,
  rgba(variables.$vermilion, 0.5) 0 2px 4px -2px;
}

.poem-accordion-item__body {
  padding: 0 32px 12px;
}

.poem-item {
  margin-bottom: 36px;
  font-size: 16px;
  line-height: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.poem-item__author {
  letter-spacing: 1.2;
  margin-bottom: 8px;
}

.poem-item__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  font-weight: bold;
  letter-spacing: 1.2;

  span {
    font-weight: normal;
  }
}

.poem-item__text {
  margin-bottom: 16px;
  //white-space: pre-line;
}

.poem-item__year {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
}

//---------- CARDS ----------------------------------------
.coloring-card {
  margin: 4px;
  padding: 24px;
  text-align: center;

  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
  rgba(0, 0, 0, 0.1) 0 2px 4px -2px;
}

.coloring-card__button {
  display: inline-block;
  margin-top: 24px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  border: 2px solid rgba(variables.$vermilion, 0.5);

  transition-property: background-color, box-shadow;
  transition-duration: .5s;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(variables.$vermilion, 0.5);
  }
}

.link-card {
  display: block;
  padding: 4px 8px 8px;
  background-color: rgba(variables.$light, 0.2);
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(variables.$vermilion, 0.5) 0 4px 6px -1px,
  rgba(variables.$vermilion, 0.5) 0 2px 4px -2px;

  transition-property: box-shadow;
  transition-duration: .5s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(variables.$vermilion, 1) 0 4px 6px -1px,
    rgba(variables.$vermilion, 1) 0 2px 4px -2px;
  }
}

.basic-card {
  margin-bottom: 32px;
  padding: 16px 32px;
  background-color: rgba(variables.$light, 0.2);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(variables.$vermilion, 0.5) 0 4px 6px -1px,
  rgba(variables.$vermilion, 0.5) 0 2px 4px -2px;

  font-size: 16px;
  line-height: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &_principles {
    display: flex;
    align-items: center;
    width: 24%;
    text-align: center;

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.basic-card__title {
  margin-bottom: 16px;
  font-weight: 600;
}
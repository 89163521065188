@use 'src/scss/base/variables';

.main-section {
  min-height: calc(100vh - 80px);
  background: linear-gradient(180deg, rgba(0,129,167,1) 65%, rgba(255,255,255,1) 100%);
  color: variables.$white;
}

.main-section__container {
  padding-top: 300px;
  padding-bottom: 100px;

  background-image: url("../img/main-bg-01.png");
  background-position: left 70px;
  background-size: auto 600px;
  background-repeat: no-repeat;
}

.main-section__content {
  width: 50%;
  margin-left: 50%;
}

.main-section__title {
  font-size: 100px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 32px;
}

.main-section__subtitle {
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 1.5;
}

.main-section__text {
  &:first-of-type {
    padding-right: 32px;
    margin-right: 44px;
    border-right: 1px solid variables.$white;
  }
}

//======================================================

.business-card {
  background: linear-gradient(188deg, variables.$night-blue 20%, variables.$light 100%);
}

.business-card__list {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
}

.business-card-item__title {
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
  color: variables.$white;
}

//======================================================
.achievements-preview-section {
  background: linear-gradient(169deg, variables.$sand 16%, variables.$white 87%);
}

.achievements-preview-section__list {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.achievements-preview-item {
  text-align: center;

  img {
    margin: 0 auto;
  }
}

.achievements-preview-item__title {
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
}

.achievements-preview-item__link {
  padding: 0 44px;
  background-color: variables.$white;
}

//======================================================
.materials-preview-section {
  background: linear-gradient(90deg, variables.$night-blue 10%, variables.$blue 50%, variables.$light 50%, variables.$sand 90%);
}

.materials-preview-section__container {
  display: flex;
  justify-content: space-between;
}

.materials-preview-section__text {
  margin-bottom: 60px;
  font-size: 18px;
  line-height: 32px;
}

.materials-preview-section__link {
  margin-top: auto;
}

.materials-preview-section__block {
  display: flex;
  flex-direction: column;
  width: 50%;

  &:first-of-type {
    align-items: flex-start;
    padding-right: 140px;
    color: variables.$white;

    .materials-preview-section__block-title {
      color: variables.$white;
    }
  }

  &:last-of-type {
    align-items: flex-end;
    text-align: right;
    padding-left: 140px;
  }
}

//======================================================
.projects-preview-section {
  background: linear-gradient(169deg, variables.$light 30%, variables.$night-blue);
}

.projects-preview-section__blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.projects-preview-section__block {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 1 50%;

  &:not(:last-of-type) {
    padding-bottom: 48px;
    margin-bottom: 48px;
    border-bottom: 1px solid variables.$white;
  }

  &:nth-of-type(2n + 1) {
    align-items: flex-start;
    padding-right: 100px;
  }

  &:nth-of-type(2n) {
    align-items: flex-end;
    text-align: right;
    padding-left: 100px;
  }
}

.projects-preview-section__block-title {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: variables.$text-color;
}

.projects-preview-section__block-text {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 32px;
}

.projects-preview-section__block-link {
  margin-top: auto;
}

//======================================================
.feedback-preview-section {
  //background: linear-gradient(90deg, variables.$light 10%, variables.$sand 50%);
  background-color: rgba(variables.$light, 0.5);
}

.feedback-preview-section__list {
  display: flex;
  justify-content: space-between;
}

.feedback-preview-section__item {
  flex: 0 0 400px;
  padding: 32px;
  text-align: center;
  background-color: variables.$white;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(0, 0, 0, 0) 0 0 0 0,
  rgba(variables.$vermilion, 0.5) 0 4px 6px -1px,
  rgba(variables.$vermilion, 0.5) 0 2px 4px -2px;
}

.feedback-preview-section__item-photo {
  margin: 0 auto;
  border-radius: 50%;
}

.feedback-preview-section__item-name {
  margin: 12px auto 24px;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
}

.feedback-preview-section__item-text {
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
  text-overflow: ellipsis;
}

//======================================================
.gallery-preview-section {
  background: linear-gradient(167deg, variables.$blue 20%, variables.$white);
}

.gallery-preview-section__link {
  width: fit-content;
  margin: 32px auto 0;
  display: block;
}

.gallery-preview-section__gallery {
  grid-template-rows: 175px 175px;
}